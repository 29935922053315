import React from 'react';

export default function Header() {
	return (
		<div className='c-header'>
			<button className='c-button c-button--primary'>
				Chill Heaven&apos;s MEE6 Leaderboard
			</button>
		</div>
	);
}
