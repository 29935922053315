import Axios from 'axios';
import React from 'react';

export default function Main() {
	const [users, setUsers] = React.useState([]);
	const [isError, setIsError] = React.useState(false);
	const [isLoading, setIsLoading] = React.useState(true);
	const [loadingDots, setLoadingDots] = React.useState('');

	const numberToWordForm = (number) => {
		const base = 1000;
		const units = ['', 'k', 'm', 'b', 't'];

		if (number < base) {
			return number.toString();
		}

		const unitIndex = Math.floor(Math.log10(number) / Math.log10(base));
		const unit = units[unitIndex];

		const convertedNumber = (number / Math.pow(base, unitIndex)).toFixed(1);

		const formattedNumber = convertedNumber.endsWith('.0')
			? convertedNumber.slice(0, -2)
			: convertedNumber;

		return `${formattedNumber}${unit}`;
	};

	const fetchUserData = async () => {
		try {
			const MAIN_URL =
				'https://mee6.xyz/api/plugins/levels/leaderboard/701459880992702615';

			let PROXY = `https://api.codetabs.com/v1/proxy?quest=${MAIN_URL}`;

			let res = await Axios.get(PROXY);
			let data = res.data;

			if (!data.players) {
				PROXY = 'https://corsproxy.io/?' + encodeURIComponent(MAIN_URL);

				res = await Axios.get(PROXY);
				data = res.data;

				if (!data || !data.players) {
					setIsError(true);
					setIsLoading(false);
					return;
				}
			}

			setUsers(data);
			setIsLoading(false);
		} catch (error) {
			setIsError(true);
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		fetchUserData();

		const interval = setInterval(() => {
			setLoadingDots((ld) => {
				if (ld.length >= 2) {
					return '';
				} else {
					return ld + '.';
				}
			});
		}, 300);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return (
		<div className='l-grid__item'>
			<div className='c-card'>
				<div className='c-card__body'>
					<ul className='c-list' id='list'>
						<li className='c-list__item'>
							<div className='c-list__grid'>
								<div className='u-text--left u-text--small u-text--medium'>
									Rank
								</div>
								<div className='u-text--left u-text--small u-text--medium'>
									Username
								</div>
								<div className='u-text--right u-text--small u-text--medium'>
									Messages
								</div>
								<div className='u-text--right u-text--small u-text--medium'>
									Level
								</div>
								<div className='u-text--right u-text--small u-text--medium'>
									Exp
								</div>
							</div>
						</li>

						{isLoading && (
							<div
								className='link'
								style={{
									display: 'block',
									textAlign: 'center',
								}}
							>
								<h1>Loading.{loadingDots}</h1>
							</div>
						)}

						{isError && (
							<div
								className='danger'
								style={{
									display: 'block',
									textAlign: 'center',
								}}
							>
								<h1>Something went wrong!</h1>
							</div>
						)}

						{users.players &&
							users.players?.map((row, idx) => (
								<li key={JSON.stringify(idx)} className='c-list__item'>
									<div className='c-list__grid'>
										<div
											className={`c-flag c-place u-bg--transparent ${
												idx + 1 === 1 && 'u-text--dark u-bg--yellow'
											}
                      ${idx + 1 === 2 && 'u-text--dark u-bg--teal'}
                      ${idx + 1 === 3 && 'u-text--dark u-bg--orange'}`}
										>
											{idx + 1}
										</div>
										<div className='c-media'>
											<img
												className='c-avatar c-media__img'
												src={`https://cdn.discordapp.com/avatars/${row?.id}/${row?.avatar}.webp?size=4096`}
												onError={(e) => {
													e.target.onerror = null;
													e.target.src =
														'https://cdn.discordapp.com/embed/avatars/0.png';
												}}
												alt='user avatar'
											/>
											<div className='c-media__content'>
												<div className='c-media__title'>{row?.username}</div>
											</div>
										</div>
										<div
											className={`u-text--right ${
												idx + 1 === 1 && 'u-text--yellow'
											} ${idx + 1 === 2 && 'u-text--teal'}
                                    ${idx + 1 === 3 && 'u-text--orange'}`}
										>
											<div className='u-mt--8'>
												<strong>{numberToWordForm(row?.message_count)}</strong>
											</div>
										</div>
										<div
											className={`u-text--right ${
												idx + 1 === 1 && 'u-text--yellow'
											} ${idx + 1 === 2 && 'u-text--teal'}
                                    ${idx + 1 === 3 && 'u-text--orange'}`}
										>
											<div className='u-mt--8'>
												<strong>{row?.level}</strong>
											</div>
										</div>
										<div
											className={`u-text--right ${
												idx + 1 === 1 && 'u-text--yellow'
											} ${idx + 1 === 2 && 'u-text--teal'}
                                    ${idx + 1 === 3 && 'u-text--orange'}`}
										>
											<div className='u-mt--8'>
												<strong>{numberToWordForm(row?.xp)}</strong>
											</div>
										</div>
									</div>
								</li>
							))}
					</ul>
				</div>
			</div>
		</div>
	);
}
