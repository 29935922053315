import React from 'react';
import Main from './Main';
import Layout from './Layout.jsx';

export default function Leaderboard() {
	return (
		<Layout>
			<Main />
		</Layout>
	);
}
