import './css/index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Leaderboard from './components/Leaderboard';

const root = ReactDOM.createRoot(document.getElementById('LMFAO'));

root.render(
	<React.StrictMode>
		<Leaderboard />
	</React.StrictMode>,
);
